import React, { useEffect } from 'react';

const RenderGoogleAds = props => {
  const { dataAdSlot, isInfoOnly } = props;

  if (!isInfoOnly) {
    return <></>;
  }

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-9171199450533933"
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
  );
};

export default RenderGoogleAds;
