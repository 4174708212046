import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isAfter } from 'date-fns';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import arrayMutators from 'final-form-arrays';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox } from '../../components';
import { DateRangePicker } from 'react-dates';
import ReactSelect from 'react-select';
import { vehicleTypesOptions } from '../../util/fieldsOptions';
import { isArrayLength } from '../../util/genericHelpers';

import css from './ListingPage.module.css';
import TermsAndConditions from '../AuthenticationPage/TermsAndConditions/TermsAndConditions';

const today = new Date();
const orientation =
  typeof window !== 'undefined' && window.matchMedia('(max-width: 1023px)').matches
    ? 'vertical'
    : 'horizontal';

function transformOptions(options) {
  return options.map(option => ({
    ...option,
    value: option.key,
    label: option.label,
  }));
}

const ClaimListingPanelComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        reviewSent,
        form,
        pristine,
        multiSelectCustomStyles,
        sendReviewInProgress,
        values,
        claimInProgress,
        dateRange,
        setDateRange,
        selectedVehicleOption,
        setSelectedVehicleOption,
      } = fieldRenderProps;

      const [focusedInput, setFocusedInput] = useState(null);

      const handleDateChange = ({ startDate, endDate }) => {
        setDateRange({ startDate, endDate });
        form.change('dateRange', { startDate, endDate });
      };

      const handleVehicleChange = option => {
        setSelectedVehicleOption(option);
        form.change('vehicleTypes', option?.label);
      };

      const onSubmit = async values => {
        await handleSubmit(values);
        setDateRange({ startDate: null, endDate: null });
        setSelectedVehicleOption(null);
      };

      const firstNamePlaceholderMessage = intl.formatMessage({
        id: 'ClaimListingPanel.firstNamePlaceholderMessage',
      });

      const lastNamePlaceholderMessage = intl.formatMessage({
        id: 'ClaimListingPanel.lastNamePlaceholderMessage',
      });
      const messagePlaceholderMessage = intl.formatMessage({
        id: 'ClaimListingPanel.messagePlaceholderMessage',
      });

      const emailPlaceholderMessage = intl.formatMessage({
        id: 'ClaimListingPanel.emailPlaceholderMessage',
      });

      const requiredMessage = intl.formatMessage({
        id: 'ClaimListingPanel.requiredFields',
      });

      const newsLetterLabel = intl.formatMessage({
        id: 'ClaimListingPanel.newsLetterLabel',
      });

      const privacyLabel = intl.formatMessage({
        id: 'ClaimListingPanel.privacyLabel',
      });

      const submitClaimLabel = intl.formatMessage({
        id: 'ClaimListingPanel.submitClaim',
      });

      const classes = classNames(rootClassName || css.root, className, css.claimListingPanel);
      const submitInProgress = sendReviewInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const vehicleOptions = transformOptions(vehicleTypesOptions);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.claimListingPanelHead}>
            <h2 className={css.orderPanelTitle}>
              {intl.formatMessage({
                id: 'ClaimListingPanel.title',
              })}
            </h2>
            <p>
              {intl.formatMessage({
                id: 'ClaimListingPanel.description',
              })}
            </p>
          </div>
          <div className={css.claimListingPanelBody}>
            <div className={classNames(css.formRow, css.halfWidth)}>
              <div className={css.formFld}>
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.firstName` : 'firstName'}
                  name="firstName"
                  placeholder={firstNamePlaceholderMessage}
                  validate={required(requiredMessage)}
                />
              </div>
              <div className={css.formFld}>
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.lastName` : 'lastName'}
                  name="lastName"
                  placeholder={lastNamePlaceholderMessage}
                  validate={required(requiredMessage)}
                />
              </div>
            </div>
            <div className={css.formRow}>
              <div className={css.formFld}>
                <FieldTextInput
                  className={css.claimFormContent}
                  type="text"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  placeholder={emailPlaceholderMessage}
                  validate={required(requiredMessage)}
                />
              </div>
            </div>
            <div className={classNames(css.formRow, css.dateFld)}>
              <div className={css.formFld}>
                <DateRangePicker
                  startDate={dateRange ? dateRange.startDate : null}
                  endDate={dateRange ? dateRange.endDate : null}
                  onDatesChange={handleDateChange}
                  focusedInput={focusedInput}
                  onFocusChange={setFocusedInput}
                  isOutsideRange={day => isAfter(day, today)}
                  showClearDates
                  startDateId="start_date"
                  endDateId="end_date"
                  startDatePlaceholderText={intl.formatMessage({
                    id: 'ClaimListingPanel.dateOfArrival',
                  })}
                  endDatePlaceholderText={intl.formatMessage({
                    id: 'ClaimListingPanel.dateOfDepature',
                  })}
                  orientation={orientation}
                  hideKeyboardShortcutsPanel
                  numberOfMonths={1}
                />
              </div>
            </div>

            <div className={css.formRow}>
              <div className={css.formFld}>
                <ReactSelect
                  className={css.customSelect}
                  options={vehicleOptions}
                  isMulti={false}
                  onChange={handleVehicleChange}
                  value={selectedVehicleOption}
                  formatOptionLabel={option => {
                    return (
                      <div className={css.option}>
                        <img src={option.icon} alt={option.label} />
                        <span>{option.label}</span>
                      </div>
                    );
                  }}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={multiSelectCustomStyles}
                />
              </div>
            </div>

            <div className={css.formRow}>
              <div className={css.formFld}>
                <FieldTextInput
                  className={css.textarea}
                  type="textarea"
                  id={formId ? `${formId}.message` : 'message'}
                  name="message"
                  placeholder={messagePlaceholderMessage}
                  validate={required(requiredMessage)}
                />
              </div>
            </div>
            <div className={classNames(css.formRow, css.checkboxFormRow)}>
              <div className={css.formFld}>
                <FieldCheckbox
                  id="newsLetter"
                  name="newsLetter"
                  label={newsLetterLabel}
                  value={true}
                />
              </div>
            </div>

            <div className={classNames(css.formRow, css.checkboxFormRow)}>
              <div className={css.formFld}>
                <TermsAndConditions intl={intl} />
              </div>
            </div>
            <div className={css.formRow}>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress || claimInProgress}
                disabled={submitDisabled}
                ready={reviewSent}
              >
                {submitClaimLabel}
              </PrimaryButton>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

ClaimListingPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendReviewError: null,
};

const { bool, func, string } = PropTypes;

ClaimListingPanelComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  reviewSent: bool.isRequired,
  sendReviewError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
};

ClaimListingPanelComponent.defaultProps = {
  multiSelectCustomStyles: {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#9cc78f',
        color: '#f6fde8',
        cursor: 'pointer',
        borderRadius: '100px',
        fontFamily: "'Alegreya Sans', sans-serif",
        fontSize: '16px',
        fontWeight: '400',
        padding: '0',
        border: 'none',
        boxShadow: 'none',
      };
    },
    menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#9cc78f',
        color: '#f6fde8',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontFamily: "'Alegreya Sans', sans-serif",
        fontSize: '16px',
        fontWeight: '400',
        padding: '0',
        margin: '0',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#9cc78f',
        color: '#f6fde8',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontFamily: "'Alegreya Sans', sans-serif",
        fontSize: '16px',
        fontWeight: '400',
      };
    },
    input: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#f6fde8',
        fontWeight: '400',
        fontSize: '16px',
        padding: '0',
        margin: '0',
      };
    },
    placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#f6fde8',
        fontWeight: '400',
        fontSize: '16px',
        padding: '0',
        margin: '0',
      };
    },
    multiValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: 'hsl(106.07deg 33.33% 67.06%)',
      };
    },
    singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#f6fde8',
        fontWeight: '400',
        fontSize: '16px',
        padding: '0',
        margin: '0',
      };
    },
  },
};

const ClaimListingPanel = compose(injectIntl)(ClaimListingPanelComponent);
ClaimListingPanel.displayName = 'ReviewForm';

export default ClaimListingPanel;
