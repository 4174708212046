import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  LandingListingCard,
  IconNextArrow,
  IconPrevArrow,
  Icons,
  ListingClaimModal,
  PrimaryButton,
  Modal,
} from '../../components';
import { findFlagUrlByCountryName } from 'country-flags-svg';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import Slider from 'react-slick';
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';

import css from './ListingPage.module.css';
import { withViewport } from '../../util/uiHelpers.js';
import {
  generateRandomEmailPrefix,
  isInfoOnly,
  isInstantBooking,
  isListingInfoOnly,
} from '../../util/dataExtractors.js';
import {
  decodeHexToUUID,
  encodeUUIDToHex,
  getDefaultLocale,
  isArrayLength,
  onSendEmail,
  showToaster,
  sortListingsByInfo,
} from '../../util/genericHelpers.js';
import CampingInSection from './CampingInSection.js';
import CampingInMobileSection from './CampingInMobileSection.js';
import classNames from 'classnames';
import { listingTypes } from '../../util/fieldsOptions.js';
import { authenticationInProgress, logout, signup } from '../../ducks/auth.duck.js';
import { addCustomEventToMP } from '../../ducks/mixPanel.duck.js';
import ClaimListingPanel from './ClaimListingPanel.js';
import { sendEmail } from '../../ducks/global.duck.js';
import { EmailTemplateId } from '../../util/enums.js';
import moment from 'moment';
import { searchTitle } from '../../routing/routeConfiguration.js';
import { sendVerificationEmail } from '../../ducks/user.duck.js';
import RenderGoogleAds from './RenderGoogleAds.js';
import { useMyContextFunctions } from '../../context/ContextFunctions.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const { UUID } = sdkTypes;
const defaultLocale = getDefaultLocale();

function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Icons name="rightArrowIcon" className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Icons name="leftArrowIcon" className={css.icon} />
    </div>
  );
}

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [claimInProgress, setClaimInProgress] = useState(false);
  const [infoOnlyMobileInProgress, setInfoOnlyMobileInProgress] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [selectedVehicleOption, setSelectedVehicleOption] = useState(null);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    onAddCustomEventToMP,
    config,
    viewport,
    routeConfiguration,
    similarListings,
    homepageSections,
    isMobileSection,
    onLogout,
    onCreateUser,
    onResendVerificationEmail,
    onSendSendgridEmail,
  } = props;

  const [isClaimListingModalOpen, setIsClaimListingModalOpen] = useState(false);

  const search = location?.search ? parse(location.search) : {};
  const viewId = search?.view ? decodeHexToUUID(search?.view) : null;
  const showClaimListing = rawParams?.id ? rawParams?.id?.split('-')?.pop() === viewId : false;

  useEffect(() => {
    if (showClaimListing) {
      setIsClaimListingModalOpen(true);
    }
  }, [showClaimListing]);

  const signupOrLoginInProgress = useSelector(authenticationInProgress);

  const isTabLayout = viewport.width > 0 && viewport.width < 1024;
  const isMobileLayout = viewport.width > 0 && viewport.width < 768;

  const sliderSettings = {
    arrows: isMobileLayout ? false : true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1500, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1300, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 992, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const onCreateGuestCheckout = values => {
    if (isAuthenticated) {
      onLogout();
    } else {
      const randomPrefix = generateRandomEmailPrefix('test+3@youandaview.com');
      onCreateUser({
        email: `test+${randomPrefix}@youandaview.com`,
        password: 'Vishal@7717689232',
        firstName: 'Guest',
        lastName: 'User',
        isGuest: true,
      }).then(() => handleOrderSubmit(values));
    }
  };

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
  } = currentListing.attributes;

  const locationDetails = publicData?.locationDetails;

  const isInfoListing = isInfoOnly(currentListing);

  const nearBuyListings =
    isArrayLength(similarListings) && similarListings?.filter(s => s?.id?.uuid !== rawParams.id);

    const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    location,
    onInitializeCardPaymentData,
    onAddCustomEventToMP,
  });

  const { onCloseGlobalModalForcefully } = useMyContextFunctions();

  const handleOrderSubmit = values => {
    onCloseGlobalModalForcefully();
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const address = publicData?.location?.address || '';
  const country = publicData?.country || (address && address?.split(' ')?.pop());
  const isInstant = isInstantBooking(currentListing);
  const categoryLabel = listingTypes.find(l => l?.key === publicData?.category);
  const isListingClosed = currentListing?.attributes?.state === LISTING_STATE_CLOSED;

  const renderExtraListingInfo = (
    <>
      {isInfoListing ? (
        <div className={css.mainContentRightHead}>
          <div className={css.instantBookingIcon}>
            {intl.formatMessage({ id: 'LandingListingCard.infoOnly' })}
          </div>
        </div>
      ) : (
        <div
          className={classNames(css.mainContentRightHead, {
            [css.spaceBetweenClass]: isInstant,
          })}
        >
          <div className={css.instantBookingIcon}>
            {/* <Icons name="camping" /> */}
            {categoryLabel?.label}
          </div>
          <div className={css.instantBookingIcon}>
            {/* <Icons name={!isInstant ? 'requestBooking' : 'instantBooking'} />{' '} */}
            {intl.formatMessage({ id: !isInstant ? 'Booking.no' : 'Booking.yes' })}
          </div>
          <div className={css.flag}>
            <img width={60} src={findFlagUrlByCountryName(country)} alt={country} />
          </div>
        </div>
      )}
    </>
  );

  const renderInfoOnlyForm = (
    <ClaimListingPanel
      dateRange={dateRange}
      setDateRange={setDateRange}
      selectedVehicleOption={selectedVehicleOption}
      setSelectedVehicleOption={setSelectedVehicleOption}
      claimInProgress={claimInProgress}
      onSubmit={async (values, form) => {
        const { firstName, lastName, email: guestEmail, vehicleTypes = '', message, dateRange } =
          values || {};

        setClaimInProgress(true);

        const dateOfArrival = moment(dateRange?.startDate).format('MM-DD-YYYY');
        const dateOfDepature = moment(dateRange?.endDate).format('MM-DD-YYYY');
        const viewId = encodeUUIDToHex(rawParams?.id);
        const listingUrl = `${config.marketplaceRootURL}${location.pathname}?view=${viewId}`;
        const customerLanguage = publicData?.language?.toUpperCase() || 'DE';

        await Promise.all([
          onSendSendgridEmail({
            templateId: EmailTemplateId.INFO_LISTING_REQUEST_TO_HOST,
            recipients: [publicData?.email],
            dynamicData: {
              marketplaceName: 'You and a view',
              listingTitle: title,
              firstName,
              lastName,
              dateOfArrival,
              dateOfDepature,
              vehicleTypes,
              message,
              guestEmail,
              listingUrl,
              customerLanguage,
            },
          }),
          onSendSendgridEmail({
            templateId: EmailTemplateId.INFO_LISTING_CONFIRMATION_TO_GUEST,
            recipients: [guestEmail],
            addToFreshworks: true,
            dynamicData: {
              marketplaceName: 'You and a view',
              firstName,
              lastName,
              listingTitle: title,
              dateOfArrival,
              dateOfDepature,
              vehicleTypes,
              message,
              guestEmail,
              customerLanguage: getDefaultLocale().toUpperCase() || 'DE',
              defaultUrl: `${config.marketplaceRootURL}/${searchTitle}?Europe&bounds=54.01228763%2C33.00957697%2C35.06638334%2C-6.1725903`,
            },
          }),
        ]);
        form.pauseValidation();
        form.reset();
        setClaimInProgress(false);
        setDateRange(null);
        setSelectedVehicleOption([]);
        setInfoOnlyMobileInProgress(false);
        showToaster(
          'success',
          'We have submitted your request. We will get back to you as soon as possible.'
        );
      }}
    />
  );

  const translatedDescription = publicData?.translations?.data
    ? publicData?.translations?.data[defaultLocale.toUpperCase()]
    : null;

  const claimListingModal = (
    <ListingClaimModal
      id="ListingClaimModal"
      isOpen={isClaimListingModalOpen}
      onCloseModal={() => setIsClaimListingModalOpen(!isClaimListingModalOpen)}
      onManageDisableScrolling={onManageDisableScrolling}
      currentListing={currentListing}
      saveEmailError={false}
      onResendVerificationEmail={onResendVerificationEmail}
    />
  );

  const renderExtraListingInfoModal = (
    <Modal
      id="renderExtraListingInfoModal"
      contentClassName={css.modalContent}
      isOpen={infoOnlyMobileInProgress}
      onClose={() => setInfoOnlyMobileInProgress(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={intl.formatMessage({ id: 'ListingClaimModal.later' })}
      isBookingModal={true}
    >
      {renderInfoOnlyForm}
    </Modal>
  );

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            <SectionGallery
              listing={currentListing}
              viewport={viewport}
              isInfoOnly={isListingInfoOnly(currentListing)}
              onManageDisableScrolling={onManageDisableScrolling}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
            <RenderGoogleAds dataAdSlot="9213629433" isInfoOnly={isInfoListing} />
            {isTabLayout ? renderExtraListingInfo : null}
            <div className={css.mainContentWrapper}>
              <div className={css.mainContentLeft}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
                <SectionTextMaybe
                  intl={intl}
                  geolocation={geolocation}
                  publicData={publicData}
                  isInfoOnly={isListingInfoOnly(currentListing)}
                  locationDetails={locationDetails}
                  authorDisplayName={authorDisplayName}
                />
                <SectionDetailsMaybe
                  isInstantBooking={isInstantBooking(currentListing)}
                  isInfoOnly={isListingInfoOnly(currentListing)}
                  description={translatedDescription?.description || description}
                  publicData={publicData}
                  tips={translatedDescription?.tips || publicData?.tips}
                  intl={intl}
                  renderGoogleAds={
                    <RenderGoogleAds dataAdSlot="9213629433" isInfoOnly={isInfoListing} />
                  }
                />
              </div>
              <div className={css.mainContentRight}>
                {isTabLayout ? null : renderExtraListingInfo}
                {isListingClosed ? null : (
                  <div className={css.orderColumnForProductLayout}>
                    {isInfoListing ? (
                      isMobileLayout ? null : (
                        renderInfoOnlyForm
                      )
                    ) : (
                      <OrderPanel
                        className={css.productOrderPanel}
                        listing={currentListing}
                        onCloseGlobalModalForcefully={onCloseGlobalModalForcefully}
                        signupOrLoginInProgress={signupOrLoginInProgress}
                        onCreateGuestCheckout={onCreateGuestCheckout}
                        isOwnListing={isOwnListing}
                        onSubmit={handleOrderSubmit}
                        authorLink={
                          <NamedLink
                            className={css.authorNameLink}
                            name="ListingPage"
                            params={params}
                            to={{ hash: '#author' }}
                          >
                            {authorDisplayName}
                          </NamedLink>
                        }
                        title={
                          <FormattedMessage
                            id="ListingPage.orderTitle"
                            values={{ title: richTitle }}
                          />
                        }
                        titleDesktop={
                          <FormattedMessage
                            id="ListingPage.orderTitle"
                            values={{ title: richTitle }}
                          />
                        }
                        author={ensuredAuthor}
                        onManageDisableScrolling={onManageDisableScrolling}
                        onContactUser={onContactUser}
                        monthlyTimeSlots={monthlyTimeSlots}
                        onFetchTimeSlots={onFetchTimeSlots}
                        onFetchTransactionLineItems={onFetchTransactionLineItems}
                        lineItems={lineItems}
                        fetchLineItemsInProgress={fetchLineItemsInProgress}
                        fetchLineItemsError={fetchLineItemsError}
                        validListingTypes={config.listing.listingTypes}
                        marketplaceCurrency={config.currency}
                        dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                        marketplaceName={config.marketplaceName}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>{' '}
            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              locationDetails={locationDetails}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            />
            {isListingClosed || isInfoListing ? (
              <div className={css.claimListingSec}>
                <h2 className={css.orderPanelTitle}>
                  {intl.formatMessage({ id: 'LandingListingCard.aboutAuthor' })}
                </h2>
                <p>{intl.formatMessage({ id: 'LandingListingCard.claimTitle' })}</p>
                <button
                  className={css.claimBtn}
                  type="button"
                  onClick={() => onSendEmail('', 'team@youandaview.com')}
                >
                  {intl.formatMessage({ id: 'LandingListingCard.claimNow' })}
                </button>
              </div>
            ) : (
              <>
                <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                <SectionAuthorMaybe
                  title={title}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={onContactUser}
                  isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                  onCloseInquiryModal={() => setInquiryModalOpen(false)}
                  sendInquiryError={sendInquiryError}
                  sendInquiryInProgress={sendInquiryInProgress}
                  onSubmitInquiry={onSubmitInquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              </>
            )}
            {isInfoListing && isMobileLayout ? (
              <div className={classNames(css.openOrderForm)}>
                <div className={css.instantBookingIconMobile}>
                  {intl.formatMessage({ id: 'LandingListingCard.infoOnly' })}
                </div>
                <PrimaryButton type="button" onClick={() => setInfoOnlyMobileInProgress(true)}>
                  <FormattedMessage id="ClaimListingPanel.title" />
                </PrimaryButton>
              </div>
            ) : null}
            <div className={css.nearBuyListings}>
              {isArrayLength(nearBuyListings) ? (
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.nearBuyListings" />
                </H4>
              ) : null}

              <Slider {...sliderSettings} initialSlide={10}>
                {isArrayLength(nearBuyListings)
                  ? nearBuyListings.map(l => (
                      <LandingListingCard
                        className={css.listingCard}
                        key={l?.id?.uuid}
                        listing={l}
                        setActiveListing={() => {}}
                      />
                    ))
                  : null}
              </Slider>
            </div>
          </div>
          <RenderGoogleAds dataAdSlot="9213629433" isInfoOnly={isInfoListing} />
          <div className={css.seoSections}>
            {isMobileLayout && homepageSections ? (
              <CampingInMobileSection homepageSections={homepageSections} />
            ) : (
              <CampingInSection />
            )}
          </div>
        </div>
        {claimListingModal}
        {renderExtraListingInfoModal}
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    similarListingsIds,
  } = state.ListingPage;
  const { homepageSections } = state.strapi;
  const { currentUser } = state.user;

  const similarListings =
    isArrayLength(similarListingsIds) && getListingsById(state, similarListingsIds);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    similarListings: isArrayLength(similarListings) ? sortListingsByInfo(similarListings) : [],
    homepageSections,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onAddCustomEventToMP: params => dispatch(addCustomEventToMP(params)),
  onCreateUser: params => dispatch(signup(params)),
  onSendSendgridEmail: params => dispatch(sendEmail(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onLogout: historyPush => dispatch(logout(historyPush)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(EnhancedListingPage);

export default ListingPage;
