import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../../util/types';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { AspectRatioWrapper, ResponsiveImage, Modal, Button, Icons } from '../../../components';
import Slider from 'react-slick';
import { getWishlist, isArrayLength } from '../../../util/genericHelpers';
import IconRightArrow from '../../../components/IconRightArrow/IconRightArrow';
import IconBackArrow from '../../../components/IconBackArrow/IconBackArrow';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../ducks/auth.duck';
import { selectCurrentUser } from '../../../ducks/user.duck';
import { updateProfile } from '../../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import classNames from 'classnames';

import css from './ListingImageGallery.module.css';
import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';

const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < 768;

const ListingImageGallery = props => {
  const {
    intl,
    images,
    imageVariants,
    listingId,
    onManageDisableScrolling = () => false,
    isInfoOnly,
  } = props;

  const [showAll, onShowAll] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const wishlist = getWishlist(currentUser);
  const routeConfiguration = useRouteConfiguration();

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const onUpdateCurrentUser = array => {
    const params = { wishlist: Array.from(new Set(array)) };

    return onUpdateProfile({
      publicData: { ...params },
    });
  };

  const items = images.map((img, i) => {
    return {
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
    };
  });

  const renderItem = (item, index) => {
    return (
      <AspectRatioWrapper key={item.alt} width={1} height={1} className={css.itemWrapper}>
        <div
          className={css.itemCentering}
          onClick={() => {
            onShowAll(true);
            setActiveIndex(index);
          }}
        >
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
          />
        </div>
      </AspectRatioWrapper>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  function RenderNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconRightArrow className={css.icon} />
      </div>
    );
  }

  function RenderPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconBackArrow className={css.icon} />
      </div>
    );
  }

  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    fade: true,
    infinite: true,
    lazyLoad: true,
    autoplay: false,
    arrows: true,
    initialSlide: activeIndex,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
  };

  const renderSliderData = (
    <>
      {isMobileLayout ? (
        <Slider className={css.gallerySec} {...settings}>
          {items.map((item, index) => renderItem(item, index))}
        </Slider>
      ) : (
        <div className={css.gallaryDesktopSec}>
          {' '}
          {items?.slice(0, 5).map((item, index) => renderItem(item, index))}
        </div>
      )}
    </>
  );

  const renderShowAllModal = (
    <Modal
      usePortal
      id="ListingImageGallery"
      modalTitle={`${intl.formatMessage({ id: 'ListingImageGallery.showAllPhotos' })}(${
        items?.length
      })`}
      isOpen={showAll}
      onClose={() => onShowAll(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      isGallaryModal={true}
    >
      <div className={css.modalGallarySlider}>
        {isArrayLength(items) && items?.length === 1 ? (
          items.map((item, index) => renderItem(item, index))
        ) : (
          <Slider className={css.gallarySec} {...settings}>
            {items.map((item, index) => renderItem(item, index))}
          </Slider>
        )}
      </div>
    </Modal>
  );

  const isFavourited = isArrayLength(wishlist) && wishlist.findIndex(e => e == listingId) > -1;

  const favouriteItem = (
    <div
      className={classNames(css.likeDislike, {
        [css.favourited]: isFavourited,
      })}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (isAuthenticated) {
          const index = wishlist && wishlist.findIndex(b => b == listingId);
          if (listingId) {
            if (index > -1) {
              wishlist && wishlist.splice(index, 1);
              const removedwishlist = Array.from(new Set(wishlist));
              onUpdateCurrentUser(removedwishlist);
            } else {
              wishlist.push(listingId);
              onUpdateCurrentUser(Array.from(new Set(wishlist)));
            }
          }
        } else {
          history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}, {}));
        }
      }}
    >
      {isFavourited ? <Icons name="favorite" /> : <Icons name="unfavorite" />}
    </div>
  );

  return (
    <div className={css.gallarySlider}>
      {isInfoOnly ? null : favouriteItem}
      {renderSliderData}
      <div className={css.viewPhotos}>
        <Button onClick={() => onShowAll(!showAll)}>
          {intl.formatMessage({ id: 'ListingImageGallery.showAll' })}({items?.length})
        </Button>
        {renderShowAllModal}
      </div>
    </div>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailVariants: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,
  thumbnailVariants: arrayOf(string),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
